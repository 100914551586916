import React from 'react'
import { Link } from 'react-router-dom'

function OfferBannerSection() {
  return (
    <section className="banner_fullwidth">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="banner_text">
              <p>Sale Off 20% All Products</p>
              <h2>New Trending Collection</h2>
              <span>We Believe That Good Design is Always in Season</span>
              <Link to="/shop">shopping Now</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OfferBannerSection
