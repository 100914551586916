import React from 'react'
import { Link } from 'react-router-dom'
import { useSelectAllCategory } from '../../shared/hooks/UseCategory'
import Header from './Header'

function Footer() {
  const [categorydata] = useSelectAllCategory()
  const { all_categorys, all_categorys_loading } = categorydata
  return (
    <footer className="footer_widgets">
      <div className="container">
        <div className="footer_top">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="widgets_container contact_us">
                <h3>About Sehari</h3>
                <div className="footer_contact">
                  <p>B-255 Saraswati Vihar</p>
                  <p>
                    Phone: <a href="tel:918826224857"> +91-8826224857</a>
                  </p>
                  <p>Email: officialsehari@gmail.com</p>
                  <div className="mt-4">
                    <a href="index.html">
                      <img src="/assets/img/logo/footer_logo.png" alt />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-4 col-6">
              <div className="widgets_container widget_menu">
                <h3>Information</h3>
                <div className="footer_menu">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/our-story">Our Story</Link>
                    </li>
                    <li>
                      <Link to="/shop">New Arrivals </Link>
                    </li>

                    <li>
                      <Link to="/contact-us">Contact Us </Link>
                    </li>
                    <li>
                      <Link to="/shop">Shop All</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-5 col-6">
              <div className="widgets_container widget_menu">
                <h3>Category</h3>
                <div className="footer_menu">
                  <ul>
                    {all_categorys &&
                      all_categorys.map((cat) => {
                        return (
                          <li>
                            <Link to={`/products/${cat.slug}`}>{cat.name}</Link>
                          </li>
                        )
                      })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-7">
              <div className="widgets_container product_widget">
                <h3>Connect With Sehari</h3>
                <div className="social-se">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/sehariofficial.in"
                        target="_blank"
                      >
                        <i className="fa fa-facebook" />
                        Facebook
                      </a>
                    </li>

                    <li>
                      <a
                        href=" https://www.linkedin.com/in/sehari-jewellery-60ab60239/"
                        target="_blank"
                      >
                        <i className="fa fa-linkedin" />
                        Linkedin
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/sehariofficial.in/"
                        target="_blank"
                      >
                        <i className="fa fa-instagram" />
                        Instagram
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCnuvrY0GrMbLjtO5VrDAVNw"
                        target="_blank"
                      >
                        <i className="fa fa-youtube" />
                        Youtube
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_middel">
          <div className="row">
            <div className="col-12">
              <div className="footer_middel_menu">
                <ul>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-and-condition">Terms & Conditions</Link>
                  </li>

                  <li>
                    <Link to="/refund-and-return-policy">
                      Refund & Return Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/shipping-and-delivery">
                      Shipping and Delivery
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="row">
            <div className="col-12 mx-auto">
              <div className="copyright_area">
                <p>
                  Copyright © 2022 Sehari Official. Design & Developed By{' '}
                  <i className="fa fa-heart"></i>
                  <a target="_blank" href="http://webgrowsolutions.com/">
                    Webgrow Solutions .
                  </a>
                  All rights reserved.
                </p>
                <img src="/assets/img/icon/papyel2.png" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
