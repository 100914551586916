import React from "react";
import Breadcrum from "../../components/breadcrum/Breadcrum";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import CategorySidebar from "../../components/product/CategorySidebar";
import ProductCard from "../../components/product/ProductCard";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { useAllProductsByCategory } from "../../shared/hooks/UseProduct";
import Pagination from "../../components/common/Pagination";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const CategoryArchieve = ({ match }) => {
  const data = useSelector((state) => state.category);
  const { all_categorys } = data;
  const [activeCategory, setActiveCategory] = useState(null);
  useEffect(() => {
    if (match.params.slug && all_categorys) {
      const filterData = all_categorys.filter(
        (item) => item.slug == match.params.slug
      );
      console.log(filterData);
      if (filterData && filterData.length > 0) {
        setActiveCategory(filterData[0]);
      }
    }
  }, [match.params.slug, all_categorys]);

  const [productdata, setPageNumber, actionCategory, setActionCategory] =
    useAllProductsByCategory();

  useEffect(() => {
    if (activeCategory) {
      setActionCategory(activeCategory._id);
    }
  }, [activeCategory]);
  const { products, products_loading, pages, page, total_products } =
    productdata;
  console.log(activeCategory, match.params.slug);

  return (
    <div>
      <Header />
      <Breadcrum title={activeCategory && activeCategory.name} />
      <div className="shop_area shop_reverse">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12">
              <div className="sidebar_widget">
                <div className="widget_list widget_categories">
                  <h2>Categories</h2>
                  <CategorySidebar />
                </div>
              </div>
              {/*sidebar widget end*/}
            </div>
            <div className="col-lg-9 col-md-12">
              {/*shop wrapper start*/}
              {/*shop toolbar start*/}
              <div className="shop_toolbar">
                <div className="orderby_wrapper">
                  <div className="page_amount">
                    <p> Showing {total_products} results</p>
                  </div>
                </div>
              </div>

              <div className="row">
                {!products_loading ? (
                  products &&
                  products.map((item) => {
                    return (
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <ProductCard product={item} />
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <Skeleton height={"280px"} />
                      <Skeleton height={"12px"} />
                      <Skeleton height={"16px"} />
                      <hr />
                      <Skeleton height={"24px"} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <Skeleton height={"280px"} />
                      <Skeleton height={"12px"} />
                      <Skeleton height={"16px"} />
                      <hr />
                      <Skeleton height={"24px"} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <Skeleton height={"280px"} />
                      <Skeleton height={"12px"} />
                      <Skeleton height={"16px"} />
                      <hr />
                      <Skeleton height={"24px"} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <Skeleton height={"280px"} />
                      <Skeleton height={"12px"} />
                      <Skeleton height={"16px"} />
                      <hr />
                      <Skeleton height={"24px"} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <Skeleton height={"280px"} />
                      <Skeleton height={"12px"} />
                      <Skeleton height={"16px"} />
                      <hr />
                      <Skeleton height={"24px"} />
                    </div>
                  </>
                )}
              </div>

              <div className="shop_toolbar t_bottom">
                <div className="pagination">
                  <Pagination
                    data={products}
                    page={page}
                    pages={pages}
                    count={total_products}
                    setPage={setPageNumber}
                    loading={products_loading}
                  />
                </div>
              </div>
              {/*shop toolbar end*/}
              {/*shop wrapper end*/}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CategoryArchieve;
