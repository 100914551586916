import React from 'react'

const CategoryBox = ({ image, name }) => {
  return (
    <div className="main-iamge">
      <img src={image}></img>
      <div className="category-name">
        <h3>{name}</h3>
      </div>
    </div>
  )
}

export default CategoryBox
