import React from 'react'
import CategoryBox from '../categorybox/CategoryBox'
import { useSelectAllCategory } from '../../shared/hooks/UseCategory'
import { URI } from '../../domain/constant'
import { Link } from 'react-router-dom'

function CategorySection() {
  const [categorydata] = useSelectAllCategory()
  const { all_categorys, all_categorys_loading } = categorydata
  return (
    <section className="category ptb-50">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div class="section_title">
              <h2>Shop By Category</h2>
            </div>
          </div>
        </div>
        <div className="shop-category pt-10">
          <div className="row">
            {all_categorys &&
              all_categorys.map((item) => {
                return (
                  <div className="col-md-3 col-6">
                    <Link to={`/products/${item.slug}`}>
                      <CategoryBox
                        image={`${URI}${item.image}`}
                        name={item.name}
                      />
                    </Link>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default CategorySection
