import React from 'react'
import Slider from 'react-slick'
function BlogSection() {
  const blog = {
    slidesToShow: 3,
    slidesToScroll: 1,

    arrows: true,

    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  }
  return (
    <section className="blog_section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section_title">
              <h2>Sehari News</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="blog_wrapper ">
            <Slider {...blog}>
              <div className="single_blog">
                <div className="blog_thumb">
                  <a href="blog-details.html">
                    <img src="/assets/img/blog/blog1.jpg" alt />
                  </a>
                </div>
                <div className="blog_content">
                  <h3>
                    <a href="blog-details.html">Blog image post</a>
                  </h3>
                  <div className="author_name">
                    <p>
                      <span>by</span>
                      <span className="themes">admin</span>/ 30 Oct 2018
                    </p>
                  </div>
                  <div className="post_desc">
                    <p>
                      Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras
                      pretium arcu ex. Aenean posuere libero eu augue
                      condimentum rhoncus. Praesent
                    </p>
                  </div>
                  <div className="read_more">
                    <a href="blog-details.html">read more</a>
                  </div>
                </div>
              </div>

              <div className="single_blog">
                <div className="blog_thumb">
                  <a href="blog-details.html">
                    <img src="assets/img/blog/blog2.jpg" alt />
                  </a>
                </div>
                <div className="blog_content">
                  <h3>
                    <a href="blog-details.html">Post with Gallery</a>
                  </h3>
                  <div className="author_name">
                    <p>
                      <span>by</span>
                      <span className="themes">admin</span>/ 30 Oct 2018
                    </p>
                  </div>
                  <div className="post_desc">
                    <p>
                      Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras
                      pretium arcu ex. Aenean posuere libero eu augue
                      condimentum rhoncus. Praesent
                    </p>
                  </div>
                  <div className="read_more">
                    <a href="blog-details.html">read more</a>
                  </div>
                </div>
              </div>

              <div className="single_blog">
                <div className="blog_thumb">
                  <a href="blog-details.html">
                    <img src="assets/img/blog/blog3.jpg" alt />
                  </a>
                </div>
                <div className="blog_content">
                  <h3>
                    <a href="blog-details.html">Post with Video</a>
                  </h3>
                  <div className="author_name">
                    <p>
                      <span>by</span>
                      <span className="themes">admin</span>/ 30 Oct 2018
                    </p>
                  </div>
                  <div className="post_desc">
                    <p>
                      Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras
                      pretium arcu ex. Aenean posuere libero eu augue
                      condimentum rhoncus. Praesent
                    </p>
                  </div>
                  <div className="read_more">
                    <a href="blog-details.html">read more</a>
                  </div>
                </div>
              </div>
              <div className="single_blog">
                <div className="blog_thumb">
                  <a href="blog-details.html">
                    <img src="/assets/img/blog/blog3.jpg" alt />
                  </a>
                </div>
                <div className="blog_content">
                  <h3>
                    <a href="blog-details.html">Post with Video</a>
                  </h3>
                  <div className="author_name">
                    <p>
                      <span>by</span>
                      <span className="themes">admin</span>/ 30 Oct 2018
                    </p>
                  </div>
                  <div className="post_desc">
                    <p>
                      Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras
                      pretium arcu ex. Aenean posuere libero eu augue
                      condimentum rhoncus. Praesent
                    </p>
                  </div>
                  <div className="read_more">
                    <a href="blog-details.html">read more</a>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlogSection
