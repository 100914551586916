import React from 'react'
import Breadcrum from '../../components/breadcrum/Breadcrum'
import Footer from '../../components/common/Footer'
import Header from '../../components/common/Header'

function RefundPolicy() {
  return (
    <div>
      <Header />
      <Breadcrum title={'Refund & Return Policy'} />
      <div className="privacy_policy_main_area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="privacy_content section_1">
                <p>
                  Thanks for Shopping at Sehari. We accept no return/exchange
                  policy.
                </p>
                <p>
                  We do every effort to fulfil your order which has been placed
                  so if once the order is confirmed then it can’t be canceled or
                  modified. However, If we are not able to process your order so
                  will contact you and discuss what best can be done with your
                  order.
                </p>
                <p>
                  Once the order has been successfully placed on website
                  sehariofficial.com that it can’t be canceled in any situation
                  so there is no point of refund of your amount.
                </p>
                <p>
                  Although If you have received any damage or wrong product then
                  your order will be eligible for exchange, replacement and
                  return.
                </p>
                <p>
                  For that, a clear and correct video recording of the opening
                  package is needed for replacement for the same product only.
                </p>
                <p>
                  You need to send the recorded video at
                  officialsehari@gmail.com. The video should be from starting
                  till end without any interruption.
                </p>
                <p>
                  In the video first all for the side of the parcel should be
                  visible that it is packed it should be shown from every side
                  with billing detail on it.
                </p>
                <p>
                  If we find that the video is edited, not clear, not recorded
                  properly and details not clear, we have the right to cancel
                  your request for product replacement.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default RefundPolicy
