import React from "react";
import { Link } from "react-router-dom";
import { useSelectAllCategory } from "../../shared/hooks/UseCategory";

function CategorySidebar() {
  const [categorydata] = useSelectAllCategory();
  const { all_categorys, all_categorys_loading } = categorydata;
  return (
    <ul>
      {all_categorys &&
        all_categorys.map((item) => {
          return (
            <li>
              <Link to={`/products/${item.slug}`}> {item.name} </Link>
            </li>
          );
        })}
    </ul>
  );
}

export default CategorySidebar;
