import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Breadcrum from "../../components/breadcrum/Breadcrum";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import CouponSection from "../../components/coupon/CouponSection";
import { URI } from "../../domain/constant";
import { useGetCart, useRemoveFromCart } from "../../shared/hooks/UseCart";
import { useApplyCoupon } from "../../shared/hooks/UseCoupon";
function Cart() {
  const [data, applyCoupon, discount, error] = useApplyCoupon();

  const [cart, total] = useGetCart();

  const [removeFromCart] = useRemoveFromCart();
  return (
    <div>
      <Header />
      <Breadcrum title={"Cart"} />
      <div className="shopping_cart_area">
        {cart && cart.length > 0 ? (
          <>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="table_desc">
                    <div className="cart_page table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th className="product_remove">Delete</th>
                            <th className="product_thumb">Image</th>
                            <th className="product_name">Product</th>
                            <th className="product-price">Price</th>
                            <th className="product_quantity">Quantity</th>
                            <th className="product_total">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cart &&
                            cart.map((item) => {
                              return (
                                <tr>
                                  <td className="product_remove">
                                    <a
                                      onClick={() => removeFromCart(item.slug)}
                                    >
                                      <i className="fa fa-trash-o" />
                                    </a>
                                  </td>
                                  <td className="product_thumb">
                                    <a href="#">
                                      <img
                                        src={`${URI}${
                                          item.product && item.product.image
                                        }`}
                                        alt
                                      />
                                    </a>
                                  </td>
                                  <td className="product_name">
                                    <a href="#">
                                      {" "}
                                      {item.product && item.product.name}{" "}
                                    </a>
                                  </td>
                                  <td className="product-price">
                                    ₹{item.sale_price}
                                  </td>
                                  <td className="product_quantity">
                                    <label>Quantity</label>{" "}
                                    <input
                                      min={0}
                                      max={100}
                                      defaultValue={1}
                                      type="number"
                                      disabled
                                      value={item.quantity}
                                    />
                                  </td>
                                  <td className="product_total">
                                    ₹{item.sale_price * item.quantity}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                    <div className="checkout_btn m-1">
                      <Link to={"/"} type="submit">
                        Continue Shopping
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/*coupon code area start*/}
              <div className="coupon_area">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <CouponSection />
                    {/* <div className="coupon_code left">
                      <h3>Coupon</h3>
                      <div className="coupon_inner">
                        <p>Enter your coupon code if you have one.</p>
                        <input
                          placeholder="Coupon code"
                          type="text"
                          value={coupon}
                          onChange={(e) => setCoupon(e.target.value)}
                        />
                        <button onClick={() => applyCoupon(coupon)}>
                          Apply coupon
                        </button>
                      </div>
                    </div> */}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="coupon_code right">
                      <h3>Cart Totals</h3>
                      <div className="coupon_inner">
                        <div className="cart_subtotal">
                          <p>Subtotal</p>
                          <p className="cart_amount">₹{total}</p>
                        </div>
                        {discount && discount > 0 ? (
                          <div className="cart_subtotal ">
                            <p>Discount</p>
                            <p className="cart_amount">-₹{discount}</p>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="cart_subtotal ">
                          <p>Shipping</p>
                          <p className="cart_amount">₹50</p>
                        </div>
                        {/* <a href="#">Calculate shipping</a> */}
                        <div className="cart_subtotal">
                          <p>Total</p>
                          <p className="cart_amount">
                            ₹{total + 50 - discount}
                          </p>
                        </div>
                        <div className="checkout_btn">
                          <Link to="/checkout">Proceed to Checkout</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*coupon code area end*/}
            </div>
          </>
        ) : (
          <>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center">
                    <img
                      src={`/images/cart-empty.jpeg`}
                      style={{ width: "200px" }}
                    />
                    <p>
                      <strong> Your cart is empty </strong>
                    </p>
                    <p>
                      {" "}
                      Looks like you have not added anything to your cart. Go
                      ahead and explore products.
                    </p>
                    <p style={{ marginBottom: "30px" }}>
                      {" "}
                      <Link to="/" className="button">
                        {" "}
                        Go To Home
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default Cart;
