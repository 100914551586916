import React from 'react'
import { connect } from 'react-redux'
import Breadcrum from '../../components/breadcrum/Breadcrum'
import Footer from '../../components/common/Footer'
import Header from '../../components/common/Header'

export const Shipping = (props) => {
  return (
    <div>
      <Header />
      <Breadcrum title={'Shipping and Delivery'} />
      <div className="privacy_policy_main_area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="privacy_content section_1">
                <p>
                  Orders are usually shipped within 24-72 working hours after
                  placement.
                </p>
                <p>
                  Our warehouse dispatches orders from Monday to Friday, and it
                  takes another 5-7 working days for the order to reach you
                  based on your location.
                </p>
                <p>
                  <strong> Note:</strong> In case any product from your order
                  got unavailable due to any reason or facing any delay, Will
                  connect you within 1-2 working days via call informing you
                  regarding possible delays.
                </p>
                <hr></hr>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Shipping)
