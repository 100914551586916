import React from 'react'
import { Link } from 'react-router-dom'

import Slider from 'react-slick'
import { URI } from '../../domain/constant'
import { useSelectAllBanner } from '../../shared/hooks/UseBanner'

function BannerSection() {
  var banner = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  }
  const [data] = useSelectAllBanner()
  const { all_banners } = data
  return (
    <div className="banner">
      <div>
        <Slider {...banner}>
          {all_banners &&
            all_banners.map((item) => {
              return (
                <div>
                  <Link to="/shop">
                    <img src={`${URI}${item.image}`} />
                  </Link>
                </div>
              )
            })}
        </Slider>
      </div>
    </div>
  )
}

export default BannerSection
