import React, { useState } from 'react'
import Breadcrum from '../../components/breadcrum/Breadcrum'
import Header from '../../components/common/Header'

const MyAccount = () => {
  const [fade, setFade] = useState('DASHBOARD')
  return (
    <div>
      <Header />
      <Breadcrum />
      <section className="main_content_area">
        <div className="container">
          <div className="account_dashboard">
            <div className="row">
              <div className="col-sm-12 col-md-3 col-lg-3">
                {/* Nav tabs */}
                <div className="dashboard_tab_button">
                  <ul role="tablist" className="nav flex-column dashboard-list">
                    <li>
                      <a
                        data-bs-toggle="tab"
                        className="nav-link active"
                        onClick={() => {
                          setFade('DASHBOARD')
                        }}
                      >
                        Dashboard
                      </a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="tab"
                        className="nav-link active"
                        onClick={() => {
                          setFade('ORDERS')
                        }}
                      >
                        Orders
                      </a>
                    </li>

                    <li>
                      <a
                        data-bs-toggle="tab"
                        className="nav-link"
                        onClick={() => {
                          setFade('ADDRESS')
                        }}
                      >
                        Addresses
                      </a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="tab"
                        className="nav-link"
                        onClick={() => {
                          setFade('ACCOUNTDETAILS')
                        }}
                      >
                        Account details
                      </a>
                    </li>
                    <li>
                      <a href="login.html" className="nav-link">
                        logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-12 col-md-9 col-lg-9">
                {/* Tab panes */}
                <div className="tab-content dashboard_content">
                  <div
                    className={
                      fade === 'DASHBOARD'
                        ? 'tab-pane fade show active '
                        : 'tab-pane fade  '
                    }
                    id="dashboard"
                  >
                    <h3>Dashboard </h3>
                    <p>
                      From your account dashboard. you can easily check &amp;
                      view your <a href="#">recent orders</a>, manage your{' '}
                      <a href="#">shipping and billing addresses</a> and{' '}
                      <a href="#">Edit your password and account details.</a>
                    </p>
                  </div>
                  <div
                    className={
                      fade === 'ORDERS'
                        ? 'tab-pane fade show active '
                        : 'tab-pane fade  '
                    }
                    id="orders"
                  >
                    <h3>Orders</h3>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Order</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Total</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>May 10, 2018</td>
                            <td>
                              <span className="success">Completed</span>
                            </td>
                            <td>₹25.00 for 1 item </td>
                            <td>
                              <a href="cart.html" className="view">
                                view
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>May 10, 2018</td>
                            <td>Processing</td>
                            <td>₹17.00 for 1 item </td>
                            <td>
                              <a href="cart.html" className="view">
                                view
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    className={
                      fade === 'ADDRESS'
                        ? 'tab-pane fade show active '
                        : 'tab-pane fade  '
                    }
                    id="address"
                  >
                    <p>
                      The following addresses will be used on the checkout page
                      by default.
                    </p>
                    <h4 className="billing-address">Billing address</h4>
                    <a href="#" className="view">
                      Edit
                    </a>
                    <p>
                      <strong>Bobby Jackson</strong>
                    </p>
                    <address>
                      <span>
                        <strong>City:</strong> Seattle
                      </span>
                      ,
                      <br />
                      <span>
                        <strong>State:</strong> Washington(WA)
                      </span>
                      ,
                      <br />
                      <span>
                        <strong>ZIP:</strong> 98101
                      </span>
                      ,
                      <br />
                      <span>
                        <strong>Country:</strong> USA
                      </span>
                    </address>
                  </div>
                  <div
                    className={
                      fade === 'ACCOUNTDETAILS'
                        ? 'tab-pane fade show active '
                        : 'tab-pane fade  '
                    }
                    id="account-details"
                  >
                    <h3>Account details </h3>
                    <div className="login">
                      <div className="login_form_container">
                        <div className="account_login_form">
                          <form action="#">
                            <p>
                              Already have an account?{' '}
                              <a href="#">Log in instead!</a>
                            </p>
                            <div className="input-radio">
                              <span className="custom-radio">
                                <input
                                  type="radio"
                                  defaultValue={1}
                                  name="id_gender"
                                />{' '}
                                Mr.
                              </span>
                              <span className="custom-radio">
                                <input
                                  type="radio"
                                  defaultValue={1}
                                  name="id_gender"
                                />{' '}
                                Mrs.
                              </span>
                            </div>{' '}
                            <br />
                            <label>First Name</label>
                            <input type="text" name="first-name" />
                            <label>Last Name</label>
                            <input type="text" name="last-name" />
                            <label>Email</label>
                            <input type="text" name="email-name" />
                            <label>Password</label>
                            <input type="password" name="user-password" />
                            <label>Birthdate</label>
                            <input
                              type="text"
                              placeholder="MM/DD/YYYY"
                              defaultValue
                              name="birthday"
                            />
                            <span className="example">(E.g.: 05/31/1970)</span>
                            <br />
                            <span className="custom_checkbox">
                              <input
                                type="checkbox"
                                defaultValue={1}
                                name="optin"
                              />
                              <label>Receive offers from our partners</label>
                            </span>
                            <br />
                            <span className="custom_checkbox">
                              <input
                                type="checkbox"
                                defaultValue={1}
                                name="newsletter"
                              />
                              <label>
                                Sign up for our newsletter
                                <br />
                                <em>
                                  You may unsubscribe at any moment. For that
                                  purpose, please find our contact info in the
                                  legal notice.
                                </em>
                              </label>
                            </span>
                            <div className="save_button primary_btn default_button">
                              <a href="#">Save</a>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default MyAccount
