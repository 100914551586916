import React from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick'
export const Testimonial = (props) => {
  const testimonial = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,

    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  }
  return (
    <div>
      <section className="main-testi ptb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div>
                <Slider {...testimonial}>
                  <div>
                    <div className="testimonial-av">
                      <div className="testimonial-avatar">
                        <img
                          src="https://www.sajanabyshagun.com/wp-content/uploads/elementor/thumbs/review-1-plzzco6b8kx3ae00dp7tvnifa9fc5x646jw5kjglpk.jpeg"
                          className="testimonial-avatar-image"
                        />
                      </div>
                      <div className="testimonial-content">
                        <div className="testimonial-rating">
                          <span className="star-rating">
                            <span style={{ width: '100%' }} />
                          </span>
                        </div>
                        <p>
                          Received the earrings yesterday. It always feels so
                          good receiving your earrings as they are just amazing.
                          ❤
                        </p>
                        <div>
                          <strong>Juhi-</strong>
                          <span>&nbsp;Happy Customer </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="testimonial-av">
                      <div className="testimonial-avatar">
                        <img
                          src="https://www.sajanabyshagun.com/wp-content/uploads/elementor/thumbs/review-1-plzzco6b8kx3ae00dp7tvnifa9fc5x646jw5kjglpk.jpeg"
                          className="testimonial-avatar-image"
                        />
                      </div>
                      <div className="testimonial-content">
                        <div className="testimonial-rating">
                          <span className="star-rating">
                            <span style={{ width: '100%' }} />
                          </span>
                        </div>
                        <p>
                          Received the earrings yesterday. It always feels so
                          good receiving your earrings as they are just amazing.
                          ❤
                        </p>
                        <div>
                          <strong>Juhi-</strong>
                          <span>&nbsp;Happy Customer </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="testimonial-av">
                      <div className="testimonial-avatar">
                        <img
                          src="https://www.sajanabyshagun.com/wp-content/uploads/elementor/thumbs/review-1-plzzco6b8kx3ae00dp7tvnifa9fc5x646jw5kjglpk.jpeg"
                          className="testimonial-avatar-image"
                        />
                      </div>
                      <div className="testimonial-content">
                        <div className="testimonial-rating">
                          <span className="star-rating">
                            <span style={{ width: '100%' }} />
                          </span>
                        </div>
                        <p>
                          Received the earrings yesterday. It always feels so
                          good receiving your earrings as they are just amazing.
                          ❤
                        </p>
                        <div>
                          <strong>Juhi-</strong>
                          <span>&nbsp;Happy Customer </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Testimonial)
