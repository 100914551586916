import React from 'react'
import Breadcrum from '../../components/breadcrum/Breadcrum'
import Footer from '../../components/common/Footer'
import Header from '../../components/common/Header'

function Contactus() {
  return (
    <div>
      <Header />

      <div className="contact_map">
        <div className="container">
          <div className="row">
            <div className="col-12">
           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.688310838842!2d77.1220160148951!3d28.69896898239165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03e0ba7330e5%3A0x17a8b7f081dbfd4!2sB%20Block%20Rd%2C%20Block%20B%2C%20Saraswati%20Vihar%2C%20Pitam%20Pura%2C%20Delhi!5e0!3m2!1sen!2sin!4v1659246111409!5m2!1sen!2sin" width="100%" height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

            </div>
          </div>
        </div>
      </div>
      <div className="contact_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="contact_message content">
                <h3>contact us</h3>
                <p>
                  Claritas est etiam processus dynamicus, qui sequitur
                  mutationem consuetudium lectorum. Mirum est notare quam
                  littera gothica, quam nunc putamus parum claram anteposuerit
                  litterarum formas human. qui sequitur mutationem consuetudium
                  lectorum. Mirum est notare quam
                </p>
                <ul>
                  <li>
                    <i className="fa fa-fax" /> Address : B-255 Saraswati Vihar
                  </li>
                  <li>
                    <i className="fa fa-phone" />{' '}
                    <a href="#">officialsehari@gmail.com</a>
                  </li>
                  <li>
                    <i className="fa fa-envelope-o" /> +91-8826224857
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="contact_message form">
                <h3>Tell us your project</h3>
                <form
                  id="contact-form"
                  method="POST"
                  action="https://htmldemo.net/monsta/monsta/assets/mail.php"
                >
                  <p>
                    <label> Your Name (required)</label>
                    <input name="name" placeholder="Name *" type="text" />
                  </p>
                  <p>
                    <label> Your Email (required)</label>
                    <input name="email" placeholder="Email *" type="email" />
                  </p>
                  <p>
                    <label> Subject</label>
                    <input name="subject" placeholder="Subject *" type="text" />
                  </p>
                  <div className="contact_textarea">
                    <label> Your Message</label>
                    <textarea
                      placeholder="Message *"
                      name="message"
                      className="form-control2"
                      defaultValue={''}
                    />
                  </div>
                  <button type="submit"> Send</button>
                  <p className="form-messege" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Contactus
