import React from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import ProductCard from '../../components/product/ProductCard'
import { useSelectAllProduct } from '../../shared/hooks/UseProduct'
function ProductSection() {
  const [productdata] = useSelectAllProduct()
  const { all_products, all_products_loading } = productdata
  const product = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  }
  return (
    <section className="product_section p_section1 product_s_three">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div class="section_title">
              <h2>Latest Products</h2>
            </div>
            <div className="product_area">
              <div className="product_container">
                <div className="">
                  <Slider {...product}>
                    {all_products &&
                      all_products.map((product) => {
                        return (
                          <ProductCard
                            product={product}
                            loading={all_products_loading}
                          />
                        )
                      })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductSection
