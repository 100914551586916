import React from 'react'
import Breadcrum from '../../components/breadcrum/Breadcrum'
import Footer from '../../components/common/Footer'
import Header from '../../components/common/Header'
import ProductCard from '../../components/product/ProductCard'
import { useAllProducts } from '../../shared/hooks/UseProduct'
import { useSelectAllCategory } from '../../shared/hooks/UseCategory'
import { Link } from 'react-router-dom'
import Pagination from '../../components/common/Pagination'
import CategorySidebar from '../../components/product/CategorySidebar'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const Shop = () => {
  const [productdata, setPageNumber] = useAllProducts()
  const {
    products,
    products_loading,
    pages,
    page,
    total_products,
  } = productdata
  const [categorydata] = useSelectAllCategory()
  const { all_categorys, all_categorys_loading } = categorydata
  return (
    <div>
      <Header />
      <Breadcrum title={'Shop'} />
      <div className="shop_area shop_reverse">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12">
              <div className="sidebar_widget">
                <div className="widget_list widget_categories">
                  <h2>Categories</h2>
                  <CategorySidebar />
                </div>
              </div>
              {/*sidebar widget end*/}
            </div>
            <div className="col-lg-9 col-md-12">
              <div className="shop_toolbar">
                <div className="orderby_wrapper">
                  <div className="page_amount">
                    <p> Showing {total_products} results</p>
                  </div>
                </div>
              </div>

              <div className="row">
                {!products_loading ? (
                  products &&
                  products.map((item) => {
                    return (
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <ProductCard product={item} />
                      </div>
                    )
                  })
                ) : (
                  <>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <Skeleton height={'280px'} />
                      <Skeleton height={'12px'} />
                      <Skeleton height={'16px'} />
                      <hr />
                      <Skeleton height={'24px'} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <Skeleton height={'280px'} />
                      <Skeleton height={'12px'} />
                      <Skeleton height={'16px'} />
                      <hr />
                      <Skeleton height={'24px'} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <Skeleton height={'280px'} />
                      <Skeleton height={'12px'} />
                      <Skeleton height={'16px'} />
                      <hr />
                      <Skeleton height={'24px'} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <Skeleton height={'280px'} />
                      <Skeleton height={'12px'} />
                      <Skeleton height={'16px'} />
                      <hr />
                      <Skeleton height={'24px'} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <Skeleton height={'280px'} />
                      <Skeleton height={'12px'} />
                      <Skeleton height={'16px'} />
                      <hr />
                      <Skeleton height={'24px'} />
                    </div>
                  </>
                )}
              </div>
              <div className="shop_toolbar t_bottom">
                <div className="pagination">
                  <Pagination
                    data={products}
                    page={page}
                    pages={pages}
                    count={total_products}
                    setPage={setPageNumber}
                    loading={products_loading}
                  />
                </div>
              </div>
              {/*shop toolbar end*/}
              {/*shop wrapper end*/}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Shop
