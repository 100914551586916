import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Footer from '../../components/common/Footer'
import Header from '../../components/common/Header'

const PageNotFound = () => {
  return (
    <div>
      <Header />
      <div className="error_section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="error_form">
                <h1>404</h1>
                <h2>Opps! PAGE NOT BE FOUND</h2>
                <p>
                  Sorry but the page you are looking for does not exist, have
                  been
                  <br /> removed, name changed or is temporarily unavailable.
                </p>

                <Link to="/">Back to home page</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound)
