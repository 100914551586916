import React from 'react'
import { connect } from 'react-redux'
import Breadcrum from '../../components/breadcrum/Breadcrum'
import Footer from '../../components/common/Footer'
import Header from '../../components/common/Header'

export const SingleOrder = (props) => {
  return (
    <div>
      <Header />
      <Breadcrum title={'Single Order '} />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="order-id">
                <div className="order-flex">
                  <div className="order-heading">
                    <h3>Order ID: 3354652525</h3>
                    <h5>Order Date : Feb 16, 2022</h5>
                  </div>
                  <div className="order-but">
                    <button className="btn btn-status">Pending</button>
                  </div>
                </div>
                <hr></hr>
                <div className="main-order-flex">
                  <div className="order-name-flex">
                    <div className="order-image">
                      <img src="https://sehari-api.webgrowsolutions.com/uploads/gallery-1657697222582.jpg"></img>
                    </div>
                    <div className="order-cate">
                      <h3>Nosepin with single nose</h3>
                    </div>
                  </div>

                  <div className="order-total">
                    <h3>₹2599.00</h3>
                  </div>
                </div>
                <hr></hr>
                <div className="payment-flex">
                  <div className="payment-status">
                    <h3>Payment Mode: Cash</h3>
                  </div>
                  <div className="delivery">
                    <h3>Delivery</h3>
                    <h5>Address</h5>
                    <p>128/619 Y Block</p>
                    <p>Kidwai Nagar </p>
                    <p>Kanpur UP 208011</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SingleOrder)
