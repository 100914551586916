import React, { useEffect } from 'react'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store/store'
import Home from './containers/home/Home'
import { loadUser } from './store/actions/auth'
import setAuthToken from './domain/setAuthToken'
import BeforeLoginRoutes from './shared/Routes/BeforeLoginRoutes'
import PrivateRoutes from './shared/Routes/PrivateRoutes'

import PageNotFound from './containers/notfound/PageNotFound'
import SingleProduct from './containers/product/SingleProduct'
import Shop from './containers/shop/Shop'
import Checkout from './containers/checkout/Checkout'
import MyAccount from './containers/myaccount/MyAccount'
import Contactus from './containers/pages/Contactus'
import Aboutus from './containers/pages/Aboutus'
import PrivacyPolicy from './containers/pages/PrivacyPolicy'
import TermsAndConditions from './containers/pages/TermsAndConditions'
import RefundPolicy from './containers/pages/RefundPolicy'
import Cart from './containers/pages/Cart'
import CategoryArchieve from './containers/shop/CategoryArchieve'
import ScrollToTop from './components/common/ScrollToTop'
import Shipping from './containers/pages/Shipping'
import SingleOrder from './containers/pages/SingleOrder'

function App() {
  useEffect(() => {
    //First we have to bring(get that) token, which is saved in local storage
    const token = localStorage.getItem('token')
    //then we will pass that token in setAuthToken method
    setAuthToken(token)
    store.dispatch(loadUser())
  }, [])

  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/product/:slug" component={SingleProduct} />
          <Route exact path="/shop" component={Shop} />
          <Route exact path="/new-arrival" component={Shop} />
          <Route exact path="/products/:slug" component={CategoryArchieve} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/my-account" component={MyAccount} />
          <Route exact path="/our-story" component={Aboutus} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/shipping-and-delivery" component={Shipping} />
          <Route exact path="/order/:id" component={SingleOrder} />
          <Route
            exact
            path="/terms-and-condition"
            component={TermsAndConditions}
          />
          <Route exact path="/contact-us" component={Contactus} />
          <Route exact path="/cart" component={Cart} />
          <Route
            exact
            path="/refund-and-return-policy"
            component={RefundPolicy}
          />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  )
}

export default App
