import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  useGetCart,
  useAddToCart,
  useDecreaseQuantity,
} from "../../shared/hooks/UseCart";
function CartComponent({ product }) {
  const [cart, total] = useGetCart();
  const [data, addData] = useAddToCart();
  const [decreaseQuantity] = useDecreaseQuantity();
  const [inCart, setInCart] = useState(false);
  const [cartElement, setCartElement] = useState(null);
  useEffect(() => {
    if (cart && product) {
      const filterData = cart.filter((item) => item.slug == product.slug);
      if (filterData && filterData.length > 0) {
        setInCart(true);
        setCartElement(filterData[0]);
      } else {
        setInCart(false);
        setCartElement(null);
      }
    }
  }, [cart, product]);

  return (
    <div className="product_variant quantity">
      {!inCart ? (
        <>
          <button
            className="button"
            onClick={() =>
              addData({
                product: product,
                sale_price: product.sale_price,
                regular_price: product.regular_price,
                slug: `${product.slug}`,
              })
            }
          >
            Add to cart
          </button>
        </>
      ) : (
        <>
          <a
            style={{
              border: "1px solid #f1f1f1",
              padding: "10px 10px",
              borderRadius: "5px",
              margin: "0px 5px",
            }}
            onClick={() => decreaseQuantity(product.slug)}
          >
            -
          </a>
          <input
            min={1}
            max={100}
            defaultValue={1}
            type="number"
            disabled
            style={{ width: "60px" }}
            value={cartElement.quantity}
          />
          <a
            style={{
              border: "1px solid #f1f1f1",
              padding: "10px 10px",
              borderRadius: "5px",
              margin: "0px 5px",
            }}
            onClick={() =>
              addData({
                product: product,
                sale_price: product.sale_price,
                regular_price: product.regular_price,
                slug: `${product.slug}`,
              })
            }
          >
            +
          </a>
          <Link to="/checkout" className="button">
            {" "}
            Checkout
          </Link>
          <Link to="/cart" className="cart-button">
            {" "}
            Go To Cart
          </Link>
        </>
      )}
    </div>
  );
}

export default CartComponent;
