import React from 'react'
import Breadcrum from '../../components/breadcrum/Breadcrum'
import Footer from '../../components/common/Footer'
import Header from '../../components/common/Header'

function Aboutus() {
  return (
    <div>
      <Header />
      <Breadcrum title={'Our Story'} />
      <div className="about_section">
        <div className="container">
          <div className="row ">
            <div className="col-6">
              <div className="about_thumb">
                <img src="assets/img/about/about1.jpg" alt />
              </div>
            </div>
            <div className="col-md-6">
              <div className="about_content">
                <h1>Welcome to Sehari!</h1>
                <p>
                  The founder of Most affordable jewellery brand "Sehari" is Ms.
                  Muskan Bhatia. Now, when we all stick out to social media for
                  enjoyment, Muskan used this as a path for work.
                </p>
                <p>
                  At the age of 23 while doing her job, she decided to start
                  something by herself and that is when she founded Sehari. This
                  was not planned as a carrier until people started showing
                  interest. A successful women does not want any Support because
                  she herself is a whole package and she proved it very well.
                </p>
                <p>
                  Now a Small Instagram page grew into a website. Giving her
                  days and night to bring out that happiness whenever you open
                  your box of happiness from Sehari.
                </p>
                <p>
                  Our main goal is to provide you High quality and super fine
                  Jewellery to people at most affordable price. We will continue
                  to serve people with lots of more items in future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="choseus_area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="chose_title">
                <h1>Why chose us?</h1>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single_chose">
                <div className="chose_icone">
                  <img src="assets/img/about/About_icon1.jpg" alt />
                </div>
                <div className="chose_content">
                  <h3>Most affordable jewellery brand</h3>
                  <p>
                    Sehari provides people best quality products at affordable
                    prices
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single_chose">
                <div className="chose_icone">
                  <img src="assets/img/about/About_icon2.jpg" alt />
                </div>
                <div className="chose_content">
                  <h3>Online support 24*7</h3>
                  <p>
                    We provide full online support to our customers. You can
                    contact us for any issue about your order
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single_chose">
                <div className="chose_icone">
                  <img src="assets/img/about/About_icon3.jpg" alt />
                </div>
                <div className="chose_content">
                  <h3>Fast packaging and shipping</h3>
                  <p>
                    we provide best packaging and fast shipping facility for our
                    customers
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Aboutus
