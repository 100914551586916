import { Form, Formik } from "formik";
import React, { useState } from "react";
import Breadcrum from "../../components/breadcrum/Breadcrum";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import * as Yup from "yup";
import { CheckBox, SelectBox, TextInput } from "../../components/Form/Form";
import { useGetCart } from "../../shared/hooks/UseCart";
import { useApplyCoupon } from "../../shared/hooks/UseCoupon";
import CouponSection from "../../components/coupon/CouponSection";
import { convertToFormData } from "../../shared/upload";
import { useHistory } from "react-router-dom";
import { useCreateOrder } from "../../shared/hooks/UseOrder";

const Checkout = () => {
  let history = useHistory();

  const [cart, total] = useGetCart();
  const [AddData, addData] = useCreateOrder();

  const [data, applyCoupon, discount, error] = useApplyCoupon();
  const [featuredImage, setFeaturedImage] = useState(null);

  const [login, setlogin] = useState(false);
  const [coupan, setcoupan] = useState(false);

  const placeOrder = async (values) => {
    values.discount = discount;
    values.cart = cart;
    values.sub_total = total;
    values.delivery_charges = 50;
    values.is_paid = false;
    values.order_date = new Date();
    values.payment_method = "COD";
    values.total = total + 50 - discount;
    const data = await convertToFormData({ values, featuredImage });
    await addData(data);
    history.push(`/thank-you`);
  };
  console.log("CART", cart);
  return (
    <div>
      <Header />

      <div className="Checkout_section" id="accordion">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* <div className="user-actions">
                <h3>
                  <i className="fa fa-file-o" aria-hidden="true" />
                  Returning customer?
                  <a
                    className={login ? "Returning collapsed" : "Returning"}
                    href="#"
                    data-bs-toggle="collapse"
                    data-bs-target="#checkout_login"
                    aria-expanded="false"
                    onClick={() => {
                      setlogin(!login);
                    }}
                  >
                    Click here to login
                  </a>
                </h3>
                <div
                  id="checkout_login"
                  className={login ? "collapse show" : "collapse"}
                  data-parent="#accordion"
                  style={{}}
                >
                  <div className="checkout_info">
                    <p>
                      If you have shopped with us before, please enter your
                      details in the boxes below. If you are a new customer
                      please proceed to the Billing &amp; Shipping section.
                    </p>
                    <form action="#">
                      <div className="form_group">
                        <label>
                          Username or email <span>*</span>
                        </label>
                        <input type="text" />
                      </div>
                      <div className="form_group">
                        <label>
                          Password <span>*</span>
                        </label>
                        <input type="text" />
                      </div>
                      <div className="form_group group_3">
                        <button type="submit">Login</button>
                        <label htmlFor="remember_box">
                          <input id="remember_box" type="checkbox" />
                          <span> Remember me </span>
                        </label>
                      </div>
                      <a href="#">Lost your password?</a>
                    </form>
                  </div>
                </div>
              </div> */}
              <div className="user-actions">
                <h3>
                  <i className="fa fa-file-o" aria-hidden="true" />
                  Have a Coupon Code?
                  <a
                    className={coupan ? "Returning " : "Returning collapsed "}
                    href="#"
                    data-bs-toggle="collapse"
                    data-bs-target="#checkout_coupon"
                    aria-expanded="false"
                    onClick={() => {
                      setcoupan(!coupan);
                    }}
                  >
                    Click here to enter your code
                  </a>
                </h3>
                <div
                  id="checkout_coupon"
                  className={coupan ? "collapse show" : "collapse "}
                  data-parent="#accordion"
                >
                  <CouponSection />
                </div>
              </div>
            </div>
          </div>
          <div className="checkout_form">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <Formik
                  initialValues={{
                    first_name: "",
                    last_name: "",
                    phone: "",
                    email: "",
                    address: "",
                    landmark: "",
                    city: "",
                    state: "",
                    pincode: "",
                    payment_method: "",
                  }}
                  validationSchema={Yup.object({
                    first_name: Yup.string().required("Required"),
                    last_name: Yup.string().required("Required"),
                    phone: Yup.string().required("Required"),
                    address: Yup.string().required("Required"),
                    city: Yup.string().required("Required"),
                    state: Yup.string().required("Required"),
                    pincode: Yup.string().required("Required"),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    await placeOrder(values);
                    resetForm();
                    setSubmitting(false);
                  }}
                >
                  {(formik) => {
                    console.log(formik);
                    return (
                      <Form>
                        <h3>Billing Details</h3>
                        <div className="row">
                          <div className="col-md-6">
                            <TextInput
                              label="First Name"
                              name="first_name"
                              type="text"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput
                              label="Last Name"
                              name="last_name"
                              type="text"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput label="Phone" name="phone" type="text" />
                          </div>
                          <div className="col-md-6">
                            <TextInput label="Email" name="email" type="text" />
                          </div>
                          <div className="col-md-12">
                            <TextInput
                              label="Address"
                              name="address"
                              type="text"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput
                              label="Landmark"
                              name="landmark"
                              type="text"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput label="City" name="city" type="text" />
                          </div>
                          <div className="col-md-6">
                            <SelectBox label="State" name="state">
                              <option value=""> Please Select </option>
                              <option value="Andhra Pradesh">
                                Andhra Pradesh
                              </option>
                              <option value="Andaman and Nicobar Islands">
                                Andaman and Nicobar Islands
                              </option>
                              <option value="Arunachal Pradesh">
                                Arunachal Pradesh
                              </option>
                              <option value="Assam">Assam</option>
                              <option value="Bihar">Bihar</option>
                              <option value="Chandigarh">Chandigarh</option>
                              <option value="Chhattisgarh">Chhattisgarh</option>
                              <option value="Dadar and Nagar Haveli">
                                Dadar and Nagar Haveli
                              </option>
                              <option value="Daman and Diu">
                                Daman and Diu
                              </option>
                              <option value="Delhi">Delhi</option>
                              <option value="Lakshadweep">Lakshadweep</option>
                              <option value="Puducherry">Puducherry</option>
                              <option value="Goa">Goa</option>
                              <option value="Gujarat">Gujarat</option>
                              <option value="Haryana">Haryana</option>
                              <option value="Himachal Pradesh">
                                Himachal Pradesh
                              </option>
                              <option value="Jammu and Kashmir">
                                Jammu and Kashmir
                              </option>
                              <option value="Jharkhand">Jharkhand</option>
                              <option value="Karnataka">Karnataka</option>
                              <option value="Kerala">Kerala</option>
                              <option value="Madhya Pradesh">
                                Madhya Pradesh
                              </option>
                              <option value="Maharashtra">Maharashtra</option>
                              <option value="Manipur">Manipur</option>
                              <option value="Meghalaya">Meghalaya</option>
                              <option value="Mizoram">Mizoram</option>
                              <option value="Nagaland">Nagaland</option>
                              <option value="Odisha">Odisha</option>
                              <option value="Punjab">Punjab</option>
                              <option value="Rajasthan">Rajasthan</option>
                              <option value="Sikkim">Sikkim</option>
                              <option value="Tamil Nadu">Tamil Nadu</option>
                              <option value="Telangana">Telangana</option>
                              <option value="Tripura">Tripura</option>
                              <option value="Uttar Pradesh">
                                Uttar Pradesh
                              </option>
                              <option value="Uttarakhand">Uttarakhand</option>
                              <option value="West Bengal">West Bengal</option>
                            </SelectBox>
                          </div>
                          <div className="col-md-6">
                            <TextInput
                              label="Pincode"
                              name="pincode"
                              type="text"
                            />
                          </div>
                          <div className="col-md-6">
                            <CheckBox name="COD"> Cash On Delivery </CheckBox>
                            {/* <CheckBox name="COD"> Online </CheckBox> */}
                          </div>
                        </div>
                        <div className="order_button">
                          <button type="submit">Proceed to Checkout</button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
              <div className="col-lg-6 col-md-6">
                <form action="#">
                  <h3>Your order</h3>
                  <div className="order_table table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart &&
                          cart.map((item) => {
                            return (
                              <tr>
                                <td>
                                  {item.product && item.product.name}
                                  <strong> × 2</strong>
                                </td>
                                <td> ₹{item.sale_price * item.quantity}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Cart Subtotal</th>
                          <td>₹{total}</td>
                        </tr>
                        {discount && discount > 0 ? (
                          <tr>
                            <th>Discount</th>
                            <td>
                              <strong>-₹{discount}</strong>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        <tr>
                          <th>Shipping</th>
                          <td>
                            <strong>₹{50}</strong>
                          </td>
                        </tr>
                        <tr className="order_total">
                          <th>Order Total</th>
                          <td>
                            <strong>₹{total + 50 - discount}</strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Checkout;
