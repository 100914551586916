import React, { useState } from "react";
import { useEffect } from "react";
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import ProductSection from "../../components/products/ProductSection";
import { useSingleProductBySlug } from "../../shared/hooks/UseProduct";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import InnerImageZoom from "react-inner-image-zoom";
import { URI } from "../../domain/constant";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CartComponent from "../../components/cart/CartComponent";
import { FacebookShareButton } from "react-share";
function SingleProduct({ match }) {
  const [data] = useSingleProductBySlug(match.params.slug);
  const { product, product_loading } = data;
  const [activeImage, setActiveImage] = useState(null);
  useEffect(() => {
    if (product) {
      setActiveImage(product.image);
    }
  }, [product]);

  return (
    <div>
      <Header />

      {!product_loading ? (
        product && (
          <>
            <div className="product_details">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="product-details-tab">
                      <div id="img-1" className="zoomWrapper single-zoom">
                        {activeImage && (
                          <InnerImageZoom
                            src={`${URI}${activeImage}`}
                            zoomSrc={`${URI}${activeImage}`}
                          />
                        )}
                      </div>
                      <div className="single-zoom-thumb">
                        <ul>
                          {product.image && (
                            <li>
                              <a onClick={() => setActiveImage(product.image)}>
                                <img src={`${URI}${product.image}`} />
                              </a>
                            </li>
                          )}
                          {product.gallery &&
                            product.gallery.map((item) => {
                              return (
                                <li>
                                  <a onClick={() => setActiveImage(item.image)}>
                                    <img src={`${URI}${item.image}`} />
                                  </a>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div
                      className="product_d_right"
                      style={{ position: "sticky", top: 10 }}
                    >
                      <h1> {product.name} </h1>
                      <div className="product_meta">
                        <span>
                          Category:{" "}
                          {product.categories &&
                            product.categories.map((item) => {
                              return (
                                <Link
                                  to={`/products/${
                                    item.category && item.category.link
                                  }`}
                                >
                                  {" "}
                                  {item.category && item.category.name}{" "}
                                </Link>
                              );
                            })}
                        </span>
                      </div>
                      <div className="product_price">
                        <span className="old_price">
                          ₹{product.regular_price}
                        </span>
                        <span className="current_price">
                          ₹{product.sale_price}
                        </span>
                      </div>
                      <div className="product_desc">
                        {product.short_description &&
                          renderHTML(product.short_description)}
                      </div>
                      <CartComponent product={product} />

                      <div className="priduct_social">
                        <ul>
                          <li>
                            <a
                              href="#"
                              title="facebook"
                              onClick={() =>
                                // window.open(
                                //   "http://www.facebook.com/share.php?u=" +
                                //     encodeURIComponent(window.location.href)
                                // )
                                window.open(
                                  "http://www.facebook.com/share.php?u=https://anysa.in"
                                )
                              }
                            >
                              <i className="fa fa-facebook" />
                            </a>
                          </li>

                          <li>
                            <a
                              href={`https://api.whatsapp.com/send?text=${window.location.href}`}
                              target="_blank"
                              title="whatsapp"
                            >
                              <i className="fa fa-whatsapp" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="product_d_info">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="product_d_inner">
                      <div className="product_info_button">
                        <ul className="nav" role="tablist">
                          <li>
                            <a
                              className="active"
                              data-bs-toggle="tab"
                              href="#info"
                              role="tab"
                              aria-controls="info"
                              aria-selected="true"
                            >
                              Description
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content">
                        <div
                          className="tab-pane fade active show"
                          id="info"
                          role="tabpanel"
                        >
                          <div className="product_info_content">
                            {product.description &&
                              renderHTML(product.description)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      ) : (
        <div className="product_details">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <Skeleton height={"70vh"} />
              </div>
              <div className="col-md-6">
                <Skeleton height={"20px"} />
                <Skeleton height={"15px"} />
                <Skeleton height={"20px"} />
                <Skeleton height={"10px"} />
                <hr />
                <Skeleton height={"20px"} />
                <Skeleton height={"20px"} />
              </div>
            </div>
          </div>
        </div>
      )}
      <ProductSection />
      <Footer />
    </div>
  );
}

export default SingleProduct;
