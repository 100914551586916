import React from 'react'

function FreeShippingSection() {
  return (
    <div className="choseus_area">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="single_chose">
              <div className="chose_icone">
                <img src="assets/img/about/About_icon1.jpg" alt />
              </div>
              <div className="chose_content">
                <h3>Most affordable jewellery brand</h3>
                <p>
                  Sehari provides people best quality products at affordable
                  prices
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single_chose">
              <div className="chose_icone">
                <img src="assets/img/about/About_icon2.jpg" alt />
              </div>
              <div className="chose_content">
                <h3>Online support 24*7</h3>
                <p>
                  We provide full online support to our customers. You can
                  contact us for any issue about your order
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single_chose">
              <div className="chose_icone">
                <img src="assets/img/about/About_icon3.jpg" alt />
              </div>
              <div className="chose_content">
                <h3>Fast packaging and shipping</h3>
                <p>
                  we provide best packaging and fast shipping facility for our
                  customers
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FreeShippingSection
