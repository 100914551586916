import React from 'react'

import BannerSection from '../../components/banner/BannerSection'
import BlogSection from '../../components/blog/BlogSection'
import CategorySection from '../../components/category/CategorySection'
import Footer from '../../components/common/Footer'

import Header from '../../components/common/Header'
import NewsLetter from '../../components/newsletter/NewsLetter'
import OfferBannerSection from '../../components/offerbanner/OfferBannerSection'

import ProductSection from '../../components/products/ProductSection'
import FreeShippingSection from '../../components/shipping/FreeShippingSection'
import Testimonial from '../../components/testimonial/Testimonial'
const Home = () => {
  return (
    <div>
      <div className="home_three_body_wrapper">
        <Header />
        <BannerSection />

        <CategorySection />

        {/* <OfferBannerSection /> */}

        <ProductSection />
        <FreeShippingSection />

        {/* <BlogSection /> */}

        <Testimonial />

        <Footer />
      </div>
    </div>
  )
}

export default Home
